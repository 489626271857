import axios from "axios";
import superagent from "superagent";
import superagentJsonapify from "superagent-jsonapify";

superagentJsonapify(superagent);

const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.tapemagazine.com/api";

const lang = JSON.parse(window.localStorage.getItem("lang")) || "ka";

const axiosClient = axios.create({
  headers: {
    "Accept-Language": lang,
  },
});

export const getLatestArticles = async (limit = 9) => {
  try {
    const response = await superagent
      .get(
        `${apiUrl}/node/article?include=field_image,field_category&page[limit]=${limit}&sort=-created&filter[langcode]=${lang}`
      )
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getRandomArticles = async (limit = 4) => {
  try {
    const response = await axiosClient.get(
      `${apiUrl}/get-random-articles?limit=${limit}&filter[langcode]=${lang}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getArticlesByCategory = async (category, limit = 9) => {
  try {
    const response = await superagent
      .get(
        `${apiUrl}/node/article?include=field_image,field_category&filter[field_category.name]=${category}&page[limit]=${limit}&sort=-created&filter[langcode]=${lang}`
      )
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getAutojournalArticles = async () => {
  try {
    const response = await axiosClient.get(
      `${apiUrl}/articles-get-autojournal-articles?filter[langcode]=${lang}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getMostReadArticles = async () => {
  try {
    const response = await axiosClient.get(`${apiUrl}/articles-get-most-read`);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const increaseViewOnArticle = async (id) => {
  try {
    const response = await axiosClient.get(
      `${apiUrl}/articles-increase-view/${id}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getAboutPage = async () => {
  try {
    const response = await superagent
      .get(`${apiUrl}/node/about_page`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getArticleByUrl = async (title) => {
  try {
    const response = await superagent
      .get(
        `${apiUrl}/node/article?include=field_category,field_posted_by,field_image&filter[field_url_title]=${title}&filter[langcode]=${lang}`
      )
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getUserByLink = async (link) => {
  try {
    const response = await superagent
      .get(`${link}?include=user_picture`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getUserByUsername = async (username) => {
  try {
    const response = await superagent
      .get(`${apiUrl}/user/user/?filter[name]=${username}&include=user_picture`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getArticlesByUser = async (username) => {
  try {
    const response = await superagent
      .get(
        `${apiUrl}/node/article/?include=field_category,field_posted_by,field_image&filter[field_posted_by.name]=${username}`
      )
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const fetchNextPage = async (link) => {
  try {
    const response = await superagent.get(link).set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const subscribeNewsletter = async (data) => {
  try {
    const response = await axiosClient.post(
      `${apiUrl}/articles-subscribe-newsletter`,
      data
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getSearchAutocomplete = async (query) => {
  try {
    const response = await axiosClient.get(
      `${apiUrl}/articles-search-autocomplete?q=${query}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getCategories = async () => {
  try {
    const response = await superagent
      .get(`${apiUrl}/taxonomy_term/categories`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const getAuthors = async () => {
  try {
    const response = await axiosClient.get(`${apiUrl}/articles-author-names`);
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const advancedSearch = async (query) => {
  try {
    const response = await axiosClient.get(
      `${apiUrl}/articles-advanced-search?${query}`
    );
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getSearchBackground = async () => {
  try {
    const response = await superagent
      .get(`${apiUrl}/node/background?include=field_background`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};

export const acceptSubscription = async (email) => {
  try {
    const response = await axiosClient.post(`${apiUrl}/accept-subscribe`, {
      email,
    });
    return response;
  } catch (ex) {
    return ex.response;
  }
};

export const getVideos = async () => {
  try {
    const response = await superagent
      .get(`${apiUrl}/node/videos/?include=field_video`)
      .set("Accept-Language", lang);
    return response.body;
  } catch (ex) {
    return ex.response;
  }
};
